const app = {
	dropdownMenus: function()
	{
		// ASSIGN DATA ATTRIBUTES

		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var subMenu = selected.next('ul');
			var hasSubMenu = (subMenu.length == 1) ? true : false;

			if(hasSubMenu == true)
			{
				e.preventDefault();

				// Show
				if(selected.parent('li').hasClass('selected') == true) {
					// Hide
					selected.parent('li').removeClass('selected');
					$('.parent-sub').hide();
				} else {
					// Show
					selected.addClass('selected');
					$('.parent-sub').hide();
					$('.parent a').removeClass('selected');
					selected.parent('li').addClass("selected");
					subMenu.fadeIn();
				}
			}
		});
	},
  mobileMenu: function() {
    var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);
				var status = target.hasClass("open");

				if(status == false)
				{
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});

					target.slideDown(function() {
						target.addClass('open');
					});
				} else {
					target.slideUp(function() {
						target.removeClass('open');
					});
				}
			});
		}

	//	$('#mobile-nav-inner-items').hcSticky({
		//	bottom:0
	//	});
	},
	cycleItems: function() {
		$('.rotate-items-slick').slick({
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 1,
			prevArrow: '<span class="btn-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			nextArrow: '<span class="btn-next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		});
	},
	homepage: function() {

	},
	validateEmail: function(email) {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
	},
	enquiryDialogForm: function() {
		var btn = $('.enquiry_frm_btn');
		btn.click(function() {
				var selected = $(this);
				var attr = selected.attr('data-sub-title');

				var data = {
					title: 'Enquire Now',
					subTitle: '',
					content: $('#enquire-frm').html()
				}

				if (typeof attr !== typeof undefined && attr !== false) {
					data.subTitle = selected.attr('data-sub-title');
				}
				if(data.subTitle != '') {
					data.title += " - " + data.subTitle;
				}

				// Open customized confirmation dialog window
				$.fancybox.open({
					type: 'html',
					src: '<div class="fc-content rounded frm-dialog">' +
						'<div class="top">' +
							'<h2>' + data.title + '</h2>' +
						'</div>' +
						'<div class="dialog-item-inner">' + data.content + '</div>' +
						'</div>',
					opts : {
						afterShow : function( instance, current ) {
							$('.frm-dialog #submit_enquiry_btn').click(function(e) {
								e.preventDefault();
								var form = $('.dialog-item-inner form');
								var validation = $('.dialog-item-inner #validation');
								var subject = "Enquiry";

								// MODIFY SUBJECT LINE
								if($('h1#pageTitle').length == 1) {
									subject += " on " + $('h1#pageTitle').html();
								}
								if(data.subTitle != '') {
									subject += ": " + data.subTitle;
								}

								form.find('#referer').val(document.referrer);
								form.find('#subject').val(subject);
								
								// CHK VALIDATION
								var valid = true;
								var inputs = form.find('input, textarea');
								
								inputs.removeClass('required');
								$.each(inputs, function(key, row) {
									var row = $(row);
									if((row.attr('required')) && (row.val() == '')) {
										row.addClass('required');
										valid = false;
									}
									if((row.attr('type') == 'email') && (row.val() != '')) {
										if(app.validateEmail(row.val()) == false) {
											row.addClass('required');
											valid = false;
										}
									}
								});

								var formData = form.serialize();

								if(valid == true) {
									$.ajax({
										type: 'POST',
										url: form.attr('action'),
										data: formData
									}).done(function(response) {
											if(response.success == true) {
												// SUBMISSION
												validation.html('Your enquiry was submitted successfully.');
												validation.removeClass('error');
												form.hide();
											} else {
												// FAIL
												validation.html('Your enquiry could not be sent.  Please try again.');
												validation.addClass('error');
											}
											validation.removeClass('hidden');
									}).fail(function(response) {
											// FAIL
											validation.html('Your enquiry could not be sent.  Please try again.');
											validation.addClass('error').removeClass('hidden');
									});
								}
							});
						}
					}
				});
		});
	},
	initTabs: function() {
		var tabs = $('#tabs-btns span');
		var tabContent = $('#tabs-content .tab-wrap-content-inner');

		$.each(tabContent, function(key,row) {
			var row = $(row);
			row.attr('data-tab-content', key);
			row.addClass('hidden');
		});

		$.each(tabs, function(key,row) {
			var row = $(row);
			row.attr('data-tab-btn', key);
			row.click(function() {
				var selected = $(this);
				tabs.removeClass('selected');
				selected.addClass('selected');
				tabContent.addClass('hidden');
				$('#tabs-content .tab-wrap-content-inner[data-tab-content=' + key + ']').removeClass('hidden').fadeIn();
			});

			if(key == 0) {
				row.trigger('click');
			}
		});
		



	},
  init: function() {

		app.dropdownMenus();
		app.mobileMenu();

		if($('#homepage').length > 0) {
			app.homepage();
		}

		if($('#tabs').length > 0) {
			app.initTabs();
		}

		if($('.enquiry_frm_btn').length > 0) {
			app.enquiryDialogForm();
		}

		if($('.rotate-items-slick').length > 0) {
			app.cycleItems();
		}

  }
}

$(window).load(function() {
  app.init();
});